<template>
 <Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
     <div class="container-fluid">

      <div class="row">
        <div class="col-md-12">

         <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Ara:
                    <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                    </b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table :items="transactionData" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">

                <template v-slot:cell(action)="data">
                  <b-dropdown bottom variant="info">
                                   <template #button-content>
                                         <i class="el-icon-more"></i>
                                    </template>
                                  <template>
                                        <b-dropdown-item @click="Delete(data.item)">
                                          <i class="el-icon-delete"></i> Sil
                                      </b-dropdown-item>
                                  </template>
                                
                            
                        </b-dropdown>
                </template>
                <template v-slot:cell(filename)="data">
                  
                    <a :href="data.item.filename" target="_blank"> İndir </a>

                </template>
                <template v-slot:cell(aktif)="data">
                  
                  <b-form-checkbox v-model="data.item.aktif" name="check-button" @change="AktifPasif(data.item)" switch></b-form-checkbox>

                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

     </div>
 </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {axiosInstance} from '../../../helpers/axios';
//import moment from 'moment';
//import Swal from 'sweetalert2'
export default {
    components: {
      Layout,
      PageHeader,

    },
    data() {
      return {
        title: "Güncelleme Listesi",
        items: [{
            text: "Anasayfa"
          },
          {
            text: "Güncelleme Listesi",
            active: true
          }
        ],
        transactionData: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        pageOptions: [5, 10, 15, 25, 50],
        filter: null,
        filterOn: [],
        sortBy: "orderid",
        sortDesc: false,
        fields: [{
            key: "action",
            label: "İşlemler"
          },{
            key: "aktif",
            label: "Pasif/Aktif"
          },{
            key: "originalname",
            sortable: true,
            label: "Original Name"
          },
          {
            key: "filename",
            sortable: true,
            label: "Download Url"
          },
          {
            key: "tampath",
            sortable: true,
            label: "Tam Path"
          },
          {
            key: "size",
            sortable: true,
            label: "Size"
          },
          {
            key: "tarih",
            sortable: true,
            label: "Tarih"
          },
          {
            key: "not",
            sortable: true,
            label: "Not"
          }
        ],
        modaldata:null
      }
    },
    computed: {
      rows() {
        return this.transactionData.length;
      },
    },
    created() {
      var vm = this;
      vm.List();
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      AktifPasif(params)
      {
         var vm = this;
         try {

          axiosInstance.post("update/aktifpasif",{
              param:params
          }).then((response)=>{
              if(response.data.error==false)
              {

                  vm.$bvToast.toast("Güncelleme İşlemi başarılı", {
                      title: `Uyari !`,
                      variant: "info",
                      solid: true
                  });

              }else{
                  vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                      title: `Uyari !`,
                      variant: "warning",
                      solid: true
                  });

              }

          }).catch(e=>{
              vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + e, {
                  title: `Uyari !`,
                  variant: "warning",
                  solid: true
              });
          })
          
         } catch (error) {
           vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
         }
      },
      List()
      {
         var vm = this;
         vm.transactionData = [];
         try {

          axiosInstance.post("update/listele").then((response) => {

            if(response.data.error==true)
            {
               vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });

            }else{

              if(response.data.data.length>0)
              {
                response.data.data.forEach(element => {
                   vm.transactionData.push({
                     id:element.id,
                     aktif:(element.aktif==1) ? true : false,
                     originalname:element.originalname,
                     filename:element.filename,
                     tampath:element.tampath,
                     size:element.size,
                     tarih:element.tarih,
                     not:element.not
                   });
                });
              }

            }

          }).catch((error) => {
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          })

          
          
         } catch (error) {

          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });

         }
      },
      Delete(params) {
        var vm = this;
        try {

          

          axiosInstance.post("update/sil",{
            id:params.id
          }).then((response) => {

            if(response.data.error==true)
            {
              vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            }else{
              vm.$bvToast.toast("Güncelleme sistemden silinmiştir", {
                title: `Uyari !`,
                variant: "info",
                solid: true
              });
              vm.List();
            }

          }).catch((error) => {
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });

          })
          
        } catch (error) {
          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
        }
      }
    },
}
</script>
<style>
.swal-wide{
    width:850px !important;
}
</style>